(function () {
    angular.module('informaApp')
        .component('userAuthentication', {
            controller: UserAuthenticationController,
            templateUrl: 'components/user/template.html'
        });

    function UserAuthenticationController($window, $location, $routeParams, $rootScope, AuthenticationService,
                                          AuthorizationErrorsCodes, ConstantsSvc, OmnitureSvc) {
        if (AuthenticationService.isLoggedIn()) {
            $location.path(ConstantsSvc.urls.table).search({});
            return;
        }

        const {code, state} = $routeParams;

        if (!code) {
            redirectToLoginPage(AuthenticationService, $window, $routeParams);
            return;
        }

        AuthenticationService.login(code)
        .then(user => {
            if (user) {
                /*
                if (!user.isEmailVerified) {
                    $location.path(ConstantsSvc.urls.emailVerification).search({});
                    return;
                }
                */
                
                if (!user.isEmailVerified || !user.active || user.salesforceId === null) { 
                    $location.path(ConstantsSvc.urls.forbidden).search({});
                    return;
                }

                $location.path(state || ConstantsSvc.urls.table).search({});
                $rootScope.$broadcast(ConstantsSvc.EVENTS.LoginSuccess);
                
            }
        })
        .catch((err) => {
            console.log(`within UserAuthenticationController AuthenticationService.login(code)`, err);
            $location.path(ConstantsSvc.urls.internalError).search({});
        });
}

    function redirectToLoginPage(AuthenticationService, $window, $routeParams) {
        const backUrl = $routeParams.back;

        AuthenticationService.generateAuthenticationUrl(backUrl).then(result => $window.location.href = result);
    }
})();
